import React from 'react'
import '../assets/css/Product.styl'
function Product() {
        return (
            <div className="product">


                <div className="flex-justify-center box">
                <div className="flex-column content  flex-justify-center">
                    <img src={require("../assets/images/logo102.png")} alt="" width="118" height="118" style={{ borderRadius: "35px" }}/>
                    <div className="fs-40 mt-24">
                    心月冥想
                    </div>
                    <div className="fs-20 mt-24 bold-200 ">
                    在忙碌的生活中找到片刻宁静，释放压力，提升专注力，增强内在的幸福感和满足感。！
                    </div>
                    <a href="https://user.quickapp.cn/?packageName=com.cardiac.medita.faranah&path=&shareUrl=&params=" target="_blank">
                        <div className="download-btn">
                        体验应用
                        </div>
                    </a>
                    <a href="xymx_q/index.html" target="_blank">
                        <div className="detail-btn">
                            查看更多截图
                        </div>
                    </a>
                </div>
                <div>
                    <img src={require("../assets/images/phone_102.png")} alt="" width="324" height="514"/>
                </div>
                </div>
                
                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_103.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo103.png")} alt="" width="118" height="118" style={{ borderRadius: "35px" }}  />
                        <div className="fs-40 mt-24">
                        灵雨扫描
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        一款高效实用的图文处理工具:支持从图片中快速提取文字，识别汽车型号和基本信息
                        </div>
                        <a href="https://user.quickapp.cn/?packageName=com.rain.sweep.humedad&path=&shareUrl=&params=k" target="_blank">
                            <div className="download-btn">
                                体验应用
                            </div>
                        </a>
                        <a href="lysm_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
               </div>

                <div className="flex-justify-center box">
                <div className="flex-column content  flex-justify-center">
                    <img src={require("../assets/images/logo104.png")} alt="" width="118" height="118" style={{ borderRadius: "35px" }}/>
                    <div className="fs-40 mt-24">
                    颜趣包
                    </div>
                    <div className="fs-20 mt-24 bold-200 ">
                    这里汇集了各种风格的表情包，无论是搞笑、可爱、沙雕还是潮流，总有一款适合您的表达需求。
                    </div>
                    <a href="https://user.quickapp.cn/?packageName=com.face.fun.hakuto&path=&shareUrl=&params=" target="_blank">
                        <div className="download-btn">
                            体验应用
                        </div>
                    </a>
                    <a href="yqb_q/index.html" target="_blank">
                        <div className="detail-btn">
                            查看更多截图
                        </div>
                    </a>
                </div>
                <div>
                    <img src={require("../assets/images/phone_104.png")} alt="" width="324" height="514"/>
                </div>
                </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_101.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo101.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        辰光记步
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        记录和统计您的运动情况，帮您在运动和健康的道路上走得更远、更健康。
                        </div>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/cgjb_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                        <a href="cgjb_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                    </div>
               </div>

                <div className="flex-justify-center box">
                <div className="flex-column content  flex-justify-center">
                    <img src={require("../assets/images/logo15.png")} alt="" width="118" height="118"/>
                    <div className="fs-40 mt-24">
                    智能WiFi秘书
                    </div>
                    <div className="fs-20 mt-24 bold-200 ">
                    智能WiFi秘书是您上网的好帮手，帮您解决网络安全问题、测试网速等都是一把好手。赶紧下载体验吧！
                    </div>
                    <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/znms/app.apk" target="_blank">
                        <div className="download-btn">
                            下载应用
                        </div>
                    </a>
                    <a href="znms/index.html" target="_blank">
                        <div className="detail-btn">
                            查看更多截图
                        </div>
                    </a>
                </div>
                <div>
                    <img src={require("../assets/images/phone_15.png")} alt="" width="324" height="514"/>
                </div>
                </div>
               

               


            </div>
        );
    }
export default Product;
